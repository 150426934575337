import React from 'react';
import { HomePage as HomePageBody } from '@ui/components/features';
import Head from 'next/head';
import env from '@ui/env';
import { auContent, nzContent } from '@content/data';

const content = env.NEXT_PUBLIC_REGION === 'AU' ? auContent : nzContent;

const HomePage = () => (
  <>
    <Head>
      <link rel="canonical" href={content['Seo.Home.canonical']} />
      <title> Fashion For Women | Hello Molly | Official Online Store</title>
      <meta key="title" name="title" content="WOMEN'S FASHION ONLINE | Official Online Store" />
      <meta
        key="description"
        name="description"
        content="Hello Molly is your go-to destination for the hottest styles and trends. Shop the latest range of dresses, playsuits, skirts, accessories and more. Our 100 new arrivals a week means you&#39;ll be looking on point for every occasion."
      />
    </Head>
    <HomePageBody />
  </>
);

export default HomePage;
